<template>
  <div class="comm-page">
    <!--rate_page_head start-->
    <div class="rate_page_head flex flex-between">
      <div class="rate_nav flex">
        <li
          @click="changeHead(index)"
          class="li"
          :class="{ active: index == headIndex }"
          v-for="(item, index) in 2"
          :key="index"
        >
          {{ index == 0 ? "我的业绩" : "他人业绩" }}
        </li>
      </div>
    </div>
    <!--rate_page_head end-->
    <!--comm-main start-->
    <div class="comm-main" v-if="headIndex == 0">
      <!--comm-hd start-->
      <div class="comm-hd">
        <div class="con">
          <div class="t">业绩(元)</div>
          <div class="c">{{ total_money }}</div>
        </div>
      </div>
      <!--comm-hd end-->
      <!--comm-bd start-->
      <div class="comm-bd">
        <div class="comm-tit">
          <div class="name">业绩明细</div>
          <div class="comm-select">
            <el-date-picker
              @change="chaneData"
              v-model="from.date_time"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
        </div>
        <!--comm-list start-->
        <div class="comm-list">
          <!--comm-item start-->
          <div class="comm-item" v-for="(item, index) in list" :key="index">
            <div class="item-l">
              <img class="icon" src="../../assets/image/personage/icon5.png" />
              <div class="txt">
                <div class="t">{{ item.content }}</div>
                <div class="d">{{ item.createtime }}</div>
              </div>
            </div>
            <div class="item-r">
              <div class="add-num">+{{ item.money }}</div>
            </div>
          </div>
          <!--comm-item end-->
        </div>
        <!--comm-list end-->
        <div class="paging" v-if="list.length">
          <el-pagination
            background
            @current-change="changePage"
            layout="prev, pager, next, jumper, ->, total"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <!--comm-bd end-->
    </div>
    <!--comm-main end-->
    <!--comm-main start-->
    <div class="comm-main" v-else>
      <!--comm-bd start-->
      <div class="comm-bd">
        <!--agree-head start-->
        <div class="agree-head">
          <div class="key_page_select">
            <div class="select_item">
              <div class="label">时间:</div>
              <div class="select">
                <el-date-picker
                  @change="chaneData"
                  v-model="from.date_time"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </div>
            </div>
            <div class="select_item">
              <div class="label">区域:</div>
              <div class="select">
                <el-cascader
                  clearable
                  size="medium"
                  :options="regionList"
                  v-model="region"
                  placeholder="请选择"
                  @change="handleChangeCity"
                >
                </el-cascader>
              </div>
            </div>
            <div class="select_item">
              <div class="label">门店:</div>
              <div class="select">
                <el-select
                  clearable
                  @change="chaneData"
                  v-model="from.dian_id"
                  placeholder="请选择"
                  size="160px"
                >
                  <el-option
                    v-for="item in shopList"
                    :key="item.id"
                    :label="item.dian_name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="select_item">
              <div class="label">职位:</div>
              <div class="select">
                <el-select
                  clearable
                  @change="chaneData"
                  v-model="from.position"
                  placeholder="请选择"
                  size="160px"
                >
                  <el-option
                    v-for="item in positionList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="select_item">
              <div class="label">姓名:</div>
              <div class="select">
                <el-input
                  @input="chaneData"
                  v-model="from.name"
                  placeholder="请输入"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
        <!--agree-head end-->
        <!--comm-list start-->
        <div class="comm-list">
          <!--comm-item start-->
          <div class="comm-item" v-for="(item, index) in list" :key="index">
            <div class="item-l">
              <img class="icon" src="../../assets/image/personage/icon5.png" />
              <div class="txt">
                <div class="t">{{ item.content }}</div>
                <div class="b">
                  <div class="b-s">
                    <span class="color-9">姓名：</span>{{ item.name }}
                  </div>
                  <div class="b-s">
                    <span class="color-9">区域：</span>{{ item.city }}
                  </div>
                  <div class="b-s">
                    <span class="color-9">门店：</span>{{ item.dian_name }}
                  </div>
                  <div class="b-s">
                    <span class="color-9">职位：</span
                    >{{ getPosition(item.position) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="item-r">
              <div class="add-num">+{{ item.money }}</div>
              <div class="d">{{ item.createtime }}</div>
            </div>
          </div>
          <!--comm-item end-->
        </div>
        <!--comm-list end-->
        <div class="paging" v-if="list.length">
          <el-pagination
            background
             @current-change="changePage"
            layout="prev, pager, next, jumper, ->, total"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <!--comm-bd end-->
    </div>
    <!--comm-main end-->
  </div>
</template>

<script>
import { regionData, CodeToText } from "element-china-area-data";
export default {
  data() {
    return {
      regionList: regionData,
      CodeToText: CodeToText,
      region: [], //区域
      headIndex: 0, //
      page: 1, //页数
      total: 0, //条数
      list: [], //列表
      from: {
        date_time: "",
        dian_id: "",
        position: "",
        name: "",
        area: "",
      },
      shopList: [], //门店列表
      total_money:0,//总金额
    };
  },
  methods: {
    changePage (e) {
      this.page = e
      this.getcommissionRecordList()
    },
    chaneData() {
      this.init();
    },
    //切换
    changeHead(index) {
      this.headIndex = index;
    },
    init() {
      this.page = 1;
      this.getcommissionRecordList();
    },
    //获取业绩列表
    getcommissionRecordList() {
      this.$axios
        .commissionRecordList({
          page: this.page,
          role_type: this.headIndex + 1,
          ...this.from,
        })
        .then((res) => {
          this.total_money = res.data.total_money
          this.list = res.data.list.data;
          this.total = res.data.list.total;
        });
    },
    //获取门店
    getmenDian() {
      this.$axios.menDian().then((res) => {
        this.shopList = res.data;
      });
    },
    //选择区域
    handleChangeCity() {
      let data = [];
      for (let i = 0; i < this.region.length; i++) {
        data.push(this.CodeToText[this.region[i]]);
      }
      this.from.area = data[2];
      this.init()
    },
  },
  created() {
    this.getmenDian();
    this.getcommissionRecordList();
  },
};
</script>

<style scoped lang="less">
.rate_page_head {
  background: #fff;
  padding: 0 40px;
  align-items: center;
  margin-bottom: 10px;
  height: 64px;
  .rate_nav {
    .li {
      font-size: 18px;
      color: #666;
      margin-right: 55px;
      cursor: pointer;
      position: relative;
      line-height: 64px;
    }
    .active {
      color: #3273f6;
    }
    .active:after {
      width: 24px;
      height: 2px;
      background: #3273f6;
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: -12px;
      display: block;
      content: "";
    }
  }
}
.comm-main {
  background: #fff;
  padding: 25px 30px;
  .comm-hd {
    width: 690px;
    height: 210px;
    background: url("../../assets/image/personage/money.png") no-repeat center;
    background-size: cover;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    .c {
      font-size: 42px;
      margin-top: 15px;
    }
    .t {
      font-size: 20px;
    }
  }
  .comm-tit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .name {
      font-size: 18px;
      padding-left: 10px;
      position: relative;
    }
    .name:before {
      display: block;
      width: 4px;
      height: 20px;
      position: absolute;
      left: 0px;
      top: 50%;
      margin-top: -10px;
      content: "";
      background: #3273f6;
      border-radius: 2px;
    }
  }
  .comm-list {
    .comm-item {
      display: flex;
      border-bottom: 1px solid #ebebeb;
      padding: 15px 0px;
      justify-content: space-between;
      align-items: center;
      .item-l {
        display: flex;
        align-items: center;
        .icon {
          width: 44px;
          height: 44px;
        }
        .txt {
          margin-left: 20px;
          .t {
            font-size: 16px;
            line-height: 1.6;
          }
          .d {
            color: #999;
            font-size: 14px;
            margin-top: 5px;
            line-height: 1.6;
          }
          .b {
            display: flex;
            font-size: 14px;
            line-height: 1.6;
            margin: 5px -20px;
          }
          .b-s {
            line-height: 1.6;
            margin: 0 20px;
          }
        }
      }
      .d {
        color: #999;
        font-size: 14px;
        margin-top: 5px;
        line-height: 1.6;
      }
      .item-r {
        text-align: right;
        .add-num {
          font-size: 24px;
          color: #ff2121;
        }
      }
    }
  }
}
.key_page_select {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -10px;
  .select_item {
    display: flex;
    font-size: 14px;
    color: #666;
    align-items: center;
    padding: 10px 0px;
    margin: 0 10px;
  }
  .label {
    margin-right: 10px;
    white-space: nowrap;
    width: 80px;
    text-align: right;
  }
  .select {
    width: 200px;
  }
  /deep/ .el-input__inner {
    border: 1px solid #ccc;
    border-radius: 0;
  }
  /deep/ .el-date-editor.el-input {
    width: 200px;
  }
}
</style>
